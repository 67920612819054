import "./Hero.css";
import React, { useState, useEffect } from "react";
import { heroContent } from "../../content";
import CloseIcon from '../../assets/close-icon.svg';

const Hero = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [validationResult, setValidationResult] = useState("");
  const [domainResult, setDomainResult] = useState("");
  const [disposableResult, setDisposableResult] = useState("");
  const [catchAllResult, setCatchAllResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validationResults, setValidationResults] = useState([]);

  useEffect(() => {
    const storedResults = localStorage.getItem('emailVerifications');
    if (storedResults) {
      setValidationResults(JSON.parse(storedResults));
    }
  }, []);

  const removeResult = index => {
    const newResults = [...validationResults];
    newResults.splice(index, 1);
    setValidationResults(newResults);
    localStorage.setItem('emailVerifications', JSON.stringify(newResults));
  };

  const verify = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setValidationResult("");
    setDomainResult("");
    setDisposableResult("");
    setCatchAllResult("");

    try {
      const response = await fetch('https://api.fraudemail.com/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const newResult = {
        email: email,
        status: data.status === "valid" ? "Valid" : "Invalid",
        domain: data.domain,
        disposable: data.disposable,
        reason: data.reason
      };

      let updatedResults = [...validationResults, newResult];
      if (updatedResults.length > 5) {
        updatedResults = updatedResults.slice(-5); // Keeps only the last 5 results
      }

      setValidationResults(updatedResults);
      localStorage.setItem('emailVerifications', JSON.stringify(updatedResults));

      if (data.status === "valid") {
        setValidationResult(`Email is valid`);
        setDomainResult(`Domain: ${data.domain}`);
        setDisposableResult(`Disposable: ${data.disposable}`);
        if (data.reason === "catchall") {
          setCatchAllResult("Cannot accurately verify this email address because the server has catch-all enabled.");
        }
      } else {
        setValidationResult(`Email is not valid`);
      }
    } catch (error) {
      setErrorMessage("Too many attempts, try again later");
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="hero" id="hero">
      <div className="firstHome">
        <div className="signupFormContainer">
          <form className="signup" onSubmit={verify}>
            <h3>{heroContent.signUpTitle}</h3>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <input
              type="email"
              className="email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              required
            />
            <button className="action-btn" type="submit" disabled={isLoading}>
              {isLoading ? 'Loading...' : heroContent.signupFree}
            </button>
            <span className="signup-notice" dangerouslySetInnerHTML={{ __html: heroContent.dataProtection }}></span>
          </form>
          <p className="heroPara">{heroContent.heroNote}</p>
        </div>
        <div className="heroContentContainer">
          <div className="hero_content">
            <h1 id="statusTitle">{heroContent.heroTitle}</h1>
            <h1 id="status" style={{
              color: catchAllResult ? 'orange' : (validationResult === "Email is valid" ? 'green' : 'red')
            }}>
              {validationResult ? (validationResult === "Email is valid" ? 'GOOD' : (catchAllResult ? 'CATCHALL' : 'BAD')) : ''}
            </h1>
            <h3 id="status">{validationResult}</h3>
            <h3 id="status">{domainResult}</h3>
            <h3 id="status">{disposableResult}</h3>
            <br />
            <p id="status">{catchAllResult}</p>
            <div className="result-chips">
              {validationResults.length > 0 ? (
                validationResults.slice().reverse().map((result, index) => (
                  <div key={index} className={`chip ${result.status.toLowerCase()} ${result.reason === "catchall" ? 'catchall' : ''}`}>
                    <span>{result.email}</span>
                    <button onClick={() => removeResult(index)} className="remove-btn">
                      <img src={CloseIcon} alt="Remove" />
                    </button>
                    <div className="full-email">{result.email}</div>
                  </div>
                ))
              ) : (
                <p className="empty-state">Start by verifying your first email.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
