import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./Layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import redirects from "./data/blog.json";
import Article from "./components/Article/Article";

const externalRedirectRoutes = redirects.map(redirect => ({
    path: redirect.path,
    element: <Article heading={redirect.heading} subheading={redirect.subheading} article={redirect.article} />,
}));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicy />
            },
            {
                path: "terms",
                element: <Terms />
            },
            ...externalRedirectRoutes,
            {
                path: "*",
                element: <Home />,
            }
        ],
    },
]);

export default router;
