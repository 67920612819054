import "./About.css";
import Info1 from "../../assets/whoweare.webp";
import Info2 from "../../assets/dataprotection.webp";
import { aboutContent } from "../../content";

const About = () => {
  return (
    <section id="about">
      <div className="aboutWrapper">
        <div className="aboutTop">
          <div className="aboutLeft">
            <h2>{aboutContent.title1}</h2>
            <p>
              {aboutContent.desc1}
            </p>
          </div>
          <div className="aboutRight">
            <img src={Info1} alt="" />
          </div>
        </div>
        <div className="aboutBottom" id="about">
          <div className="aboutLeft">
            <img src={Info2} alt="" />
          </div>
          <div className="aboutRight">
            <h2>{aboutContent.title2}</h2>
            <p dangerouslySetInnerHTML={{ __html: aboutContent.desc2 }}></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
