import ArticleList from "../ArticleList/ArticleList";
import Footer from "../Footer/Footer";
import Logo from "../Logo/Logo";
import "./Article.css";

const Article = ({ heading, subheading, article, imgPath }) => {
  return (
    <section id="article">
      <Logo />
      <div className="articleWrapper">
        <div className="articleTop">
          <div className="articleLeft">
            <h2>{heading}</h2>
            <p dangerouslySetInnerHTML={{ __html: subheading }} />
          </div>
          <div className="articleRight">
            <img src={imgPath} alt="" />
          </div>
        </div>
        <div className="articleBottom" id="article">
          <div className="articleLeft">
            <p dangerouslySetInnerHTML={{ __html: article }} />
          </div>
        </div>
      </div>
      <ArticleList />
      <Logo />
      <Footer />
    </section>
  );
};

export default Article;
