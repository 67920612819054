import Hero from "../components/Hero/Hero.jsx";
import About from "../components/About/About.jsx";
import Started from "../components/Started/Started.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Logo from '../components/Logo/Logo.jsx'
import Spacer from "../components/Spacer/Spacer.jsx";
import ArticleList from "../components/ArticleList/ArticleList.jsx";

function App() {
  return (
    <div>
      <Logo />
      <Started />
      <main>
        <Hero />
      </main>
      <Spacer height="50px" className="responsive-spacer" />
      <About />
      <ArticleList />
      <Logo />
      <Footer />
    </div>
  );
}

export default App;

