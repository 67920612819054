import "./Spacer.css";

const Spacer = ({ height, className }) => {
    const style = {
        height: height,
        width: '100%',
        display: 'block',
        backgroundColor: '#5D9CB8',
    };

    return <div className={className} style={style} />;
};

export default Spacer;