import React, { useState, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import FormDataContext from "./contexts/FormDataContext";
import router from "./router";

const App = () => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
  }, []);

  return (
    <FormDataContext.Provider value={{ formData, setFormData }}>
      <RouterProvider router={router} />
    </FormDataContext.Provider>
  );
};

export default App;