import "./Started.css";
import { startedContent } from '../../content';

const Started = ({ text }) => {
  return (
    <section id="started">
      <div>
        <h1><p dangerouslySetInnerHTML={{ __html: startedContent.text }}></p></h1>
      </div>
    </section>
  );
};

export default Started;
