export const general = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    dataProtection: "Your data is always protected with us. Read our <a href='/privacy-policy'>Privacy Policy</a>.",
    allFieldsError: "Oops! Looks like you missed a field. Please complete all fields.",
    heroNote: "",
}

export const homeContent = {
    how: "How It Works",
    dataProtection: "Data Protection",
    faq: "FAQs",
    started: "Start earning cash today and sign up for FREE!",
}

export const heroContent = {
    heroTitle: "Result",
    status: "GOOD",
    description: "The email is valid!",
    signUpTitle: "Enter the email address you want to verify below:",
    heroNote: general.heroNote,
    signupFree: "VERIFY NOW",
    dataProtection: general.dataProtection,
    allFieldsError: general.allFieldsError,
    months: general.months,
};

export const aboutContent = {
    title1: "What is Fraud Email?",
    desc1: "Fraud Email is a simple little tool for verifying an email address. It's free and quite easy to use. Just enter the email address and hit the check button. It tells you whether the email id is real or fake. Sometimes, it’s unable to correctly predict and say unknown because some email providers have put some limits and restrictions or they simply don’t like any verification checks on their mailboxes.",
    title2: "How do we verify an email?",
    desc2: "<ul class='checkmark-list'><li>First we check the format.</li><li>Then make sure that the domain name is valid.</li><li>Check if it's a known disposable domain.</li><li>Extract the MX records and connect to the email server.</li></ul><br/>Some email servers do not cooperate and in such cases the result may not be as accurate as expected.",
};

export const howItWorks = {
    title: "How It Works",
    subtitle: "Earning is easy! Just follow these 3 simple steps:",
    stepOneTitle: "Sign Up For Free",
    stepOneSubtitle: "Quickly register using our form and get matched with leading survey companies. It's effortless and completely free!",
    stepTwoTitle: "Choose & Complete Surveys",
    stepTwoSubtitle: "We'll send regular survey invites your way. Seize every chance for maximized earnings.",
    stepThreeTitle: "Earn Rewards - Cash or Gift Cards",
    stepThreeSubtitle: "Receive your rewards as you prefer – be it cash or vouchers for your favorite stores!",
}

export const offersContent = {
    months: general.months,
    dynamicMessages: {
        "subtitle": "Great news! Survey panels seek new {gender} members aged under {age} to join by {formattedNextWeek}. Choose your panels – more selections mean higher earnings.",
    },
    popupTitle: "Additional Information Needed",
    popupDesc: "To complete your sign-up with selected survey companies, please provide your address. Start earning now!",
    postCode: "Postcode",
    enterCode: "Enter Your Code",
    continue: "CONTINUE",
    nextStepTitle: "NEXT: Choose Survey Panels to Join",
    tip: "PRO TIP: Opt for all and respond to email invites for peak earnings.",
    selectAll: "<span>SELECT ALL</span> & Potentially Earn $300/month",
    footnote: "* Based on completing 2-3 surveys daily (about an hour's work daily)",
    errorSelectOne: "Please select at least one offer",
    continue: "PROCEED TO FINAL STEP",
}

export const tasksContent = {
    months: general.months,
    subtitle: "All by answering simple questions for some of the world's biggest companies.",
    continue: "CONTINUE",
    title: "Earn extra income for your time... even with an existing&nbsp;job</span>",
    featuredTasks: "Featured Tasks",
    started: "Start earning cash today!",
}

export const lastStepContent = {
    "title": "FINAL STEP: Check Your Email Now",
    "subtitle": "Your first surveys await! Confirm your registration with each selected company via email to start earning. <br/><span>IMPORTANT: This step is crucial for payment eligibility.</span>",
    "stepsTitle": "What to Do Next",
    "stepsSub": "To begin earning, you need to:",
    "step1Title": "Check Your Emails Immediately",
    "step1Content": "Look for emails from the companies you chose.",
    "step2Title": "Confirm via Email Links",
    "step2Content": "Essential step! Confirmation links must be clicked for survey company acknowledgment.",
    "step3Title": "Don't Forget Your Spam/Junk Folder",
    "step3Content": "Activation emails may sometimes land there.",
    "rewardsTitle": "Enjoy Rewards From"
}

export const brandsContent = {
    title: "Earn Rewards from these major brands",
}

export const faqContent = {
    title: "FAQs",
    subtitle: "Your Questions Answered",
    faqData: [
        {
            id: "1",
            question: "How does it work?",
            answer: "answer for how it works"
        },
        {
            id: "2",
            question: "How much does it cost?",
            answer: "answer for how much it costs"
        },
        {
            id: "3",
            question: "How do I start earning?",
            answer: "Getting started is a breeze! Just sign up through our form to explore survey options. Select surveys, click YES, and watch for confirmation emails from the companies. Don’t forget to check your spam/junk folder occasionally. Once confirmed, you’ll receive surveys to complete and start earning rewards!"
        }
    ],
}

export const startedContent = {
    text: "<span>Fraud Email</span> is a free little tool that helps you find out whether an email address is valid or not, within a few seconds!",
}
export const infoContent = {
    title: "What is Fraud Email?",
    text: "Fraud Email is a simple tool for verifying an email address. It's free and easy to use. Just enter the email address and hit the verify button. It tells you whether the email is real or fake. Sometimes it's unable to correctly predict and say unknown because some email providers have put some limits and restrictions or they simply don't like any verification checks on their mailboxes.",
}

export const footerContent = {
    copy: "© Fraudemail.com. All Rights Reserved • <a href='/privacy-policy'>Privacy Policy</a> • <a href='/terms'>Terms and Conditions</a>",
}

export default heroContent;