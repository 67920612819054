import "./ArticleList.css";
import blogPosts from "../../data/blog.json";

const ArticleList = () => {
    return (
        <section id="article-list">
            <div className="article-list-container">
                <ul>
                    {blogPosts.map(post => (
                        <li key={post.id} className="article-post">
                            <a href={post.path}>{post.heading}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default ArticleList;