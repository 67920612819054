import React from 'react';
import "./Footer.css";
import { footerContent } from '../../content';

const Footer = () => {
  return (
    <footer>
      <div className="footerCopywrite"><p dangerouslySetInnerHTML={{ __html: footerContent.copy }} /></div>
    </footer>
  );
};

export default Footer;
