import React from "react";
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Layout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default Layout;